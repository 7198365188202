<template>
  <div>
    <div class="text-center px-10">
      <h2 class="title p-relative mt-5 mb-5 weight-100">
        {{ $t('Select a category') }}
        <img
          src="/new-assets/icon/long-arrow-left-light.svg"
          alt=""
          class="left-arrow pointer"
        />
        <router-link
          :to="{
            name: 'ProductsM'
          }"
          tag="a"
          exact
        >
          <img
            src="/new-assets/icon/long-arrow-left-light.svg"
            alt=""
            class="right-arrow pointer"
          />
        </router-link>
      </h2>
      <div class="hot-sale">
        {{ $t('Hot sales') }}
      </div>
      <div class="category-block mt-10">
        <div class="category-item" @click="getProductByTag(27)" ref="tag27">
          <div class="category-img">
            <img src="/new-assets/icon/toilet.png" alt="" style="width: 97px" />
          </div>
          <p class="fs16 weight-700">נייר ומוצריו</p>
        </div>
        <div class="category-item" @click="getProductByTag(28)" ref="tag28">
          <div class="category-img">
            <img src="/new-assets/icon/building.png" alt="" />
          </div>
          <p class="fs16 weight-700">מוסדי</p>
        </div>
        <div class="category-item" @click="getProductByTag(29)" ref="tag29">
          <div class="category-img">
            <img src="/new-assets/icon/7__97915.png" alt="" />
          </div>
          <p class="fs16 weight-700">משלימים</p>
        </div>
        <div class="category-item" @click="getProductByTag(30)" ref="tag30">
          <div class="category-img">
            <img src="/new-assets/icon/1__10836.png" alt="" />
          </div>
          <p class="fs16 weight-700">חיטוי וניקיון</p>
        </div>
        <div class="category-item" @click="getProductByTag(31)" ref="tag31">
          <div class="category-img">
            <img src="/new-assets/icon/2__28275.png" alt="" />
          </div>
          <p class="fs16 weight-700">אקונומיקה</p>
        </div>
        <div class="category-item" @click="getProductByTag(32)" ref="tag32">
          <div class="category-img">
            <img src="/new-assets/icon/3__95670.png" alt="" />
          </div>
          <p class="fs16 weight-700">הדחת כלים</p>
        </div>
        <div class="category-item" @click="getProductByTag(33)" ref="tag33">
          <div class="category-img">
            <img src="/new-assets/icon/4__90756.png" alt="" />
          </div>
          <p class="fs16 weight-700">תרסיסים</p>
        </div>
        <div class="category-item" @click="getProductByTag(34)" ref="tag34">
          <div class="category-img">
            <img src="/new-assets/icon/5__23400.png" alt="" />
          </div>
          <p class="fs16 weight-700">כביסה</p>
        </div>
        <div class="category-item" @click="getProductByTag(35)" ref="tag35">
          <div class="category-img">
            <img src="/new-assets/icon/6__68478.png" alt="" />
          </div>
          <p class="fs16 weight-700">ניקוי רצפות</p>
        </div>
        <div class="category-item" @click="getProductByTag(36)" ref="tag36">
          <div class="category-img">
            <img src="/new-assets/icon/norture.png" alt="" />
          </div>
          <p class="fs16 weight-700">טיפוח</p>
        </div>
        <div class="category-item" @click="getProductByTag(37)" ref="tag37">
          <div class="category-img">
            <img
              src="/new-assets/icon/webp.net-resizeimage__14137.png"
              alt=""
            />
          </div>
          <p class="fs16 weight-700">אסלות וחדרי רחצה</p>
        </div>
        <div class="category-item" @click="getProductByTag(38)" ref="tag38">
          <div class="category-img">
            <img src="/new-assets/icon/12__63945.png" alt="" />
          </div>
          <p class="fs16 weight-700">חד פעמי</p>
        </div>
      </div>
    </div>
    <loading
      :active.sync="getProductsInProgress"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'SastechCategoryM',
  components: {
    Loading,
  },
  data: () => {
    return {
      selectedTags: [],
    };
  },
  computed: {
    ...mapState({
      getProductsInProgress: (state) =>
        state.productShaniv.getProductsInProgress,
    }),
  },
  methods: {
    ...mapActions('productShaniv', {
      getProducts: 'getProducts',
    }),
    getProductByTag(id) {
      if (!this.selectedTags.includes(id)) {
        this.selectedTags.push(id);
        this.$refs['tag' + id].classList.add('active');
      } else {
        this.removeA(this.selectedTags, id);
        this.$refs['tag' + id].classList.remove('active');
      }
      this.loadProducts();
    },
    loadProducts() {
      let params = {
        active: 1,
        user_prices: 1
      };

      if (this.selectedTags) {
        params.tag = this.selectedTags;
      }
      if (this.selectedMf) {
        params.SPEC14 = this.selectedMf;
      }

      this.getProducts(params);
    }
  },
};
</script>


<style lang="scss" scoped>
.left-arrow {
  position: absolute;
  right: 0px;
  top: 15px;
}
.right-arrow {
  position: absolute;
  left: 0px;
  top: 15px;
  transform: rotate(180deg);
}
.hot-sale {
  background-color: #fff;
  border: 2px solid #79edfb;
  border-radius: 7px;
  color: #234395;
  font-size: 30px;
  font-weight: 700;
  padding: 5px 0;
}
.category-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #234395;
  .category-item {
    width: calc(33.33% - 10px);
    margin-bottom: 30px;
    cursor: pointer;
    &.active .category-img {
      box-shadow: 0 3px 6px #222;
      border-width: 3px;
    }
    .category-img {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #234395;
      border-radius: 20px;
      background-color: #fff;
      height: 110px;
      margin-bottom: 5px;
    }
  }
}
</style>